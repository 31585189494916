<template>
  <v-card
    class="mx-auto note-card"
    :max-width="windowWidth"
    :min-width="300"
    :loading="loading"
    :disabled="loading"
  >
    <v-img 
      class="note-img"
      v-if="note.cover_photo"
      @click="onToggleNoteExpansion(note)"
      :src="`${baseImageUrl}?path=${note.cover_photo}`"
      height="200px"
    />
    <v-card-title @click="onToggleNoteExpansion(note)">
      {{ note.title }}
    </v-card-title>
    <card-action
      :note="note"
      :modal-mode="modalOpened"
      @toggleNoteDetails="show = !show"
      @openEditModal="$emit('openEditModal', note)"
      @noteOutOfScoped="$emit('noteOutOfScoped', note)"
      @cardLoadingToggled="(_loading) => loading = _loading"
    />
    <card-details-expand
      :show="modalOpened ? modalOpened : show"
      :note="note"
    />
  </v-card>
</template>

<script>
import { BASE_IMAGE_URL } from "@/utils/constants";
import CardAction from "./CardAction.vue";
import CardDetailsExpand from "./CardDetailsExpand.vue";

export default {
  name: "EachNoteCard",
  components: { CardAction, CardDetailsExpand },
  props: {
    note: {
      type: Object,
      required: true,
    },
    windowWidth: {
      type: Number,
      required: false,
      default: 350
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    modalOpened: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    loading: false,
    show: false,
    baseImageUrl: BASE_IMAGE_URL,
  }),
  methods: {
    onToggleNoteExpansion(note) {
      this.$emit("onToggleNoteExpansion", note);
    }
  },
};
</script>
<style lang="scss">
.note-card {
  overflow: hidden !important;

  .note-img {
    cursor: pointer;
  }

  .v-card {
    display: flex !important;
    flex-direction: column;
  }
  .v-card__text {
    height: 200px;
    flex-grow: 1;
    overflow: auto;
  }
  .v-card__title {
    cursor: pointer;
    font-size: 1.2rem;
    padding: 7px 17px;
  }
  .v-card__actions {
    padding-top: 2px;
    margin-left: -55px;
  }
  .hidden-action-btn {
    display: block;
  }
  &:hover {
    .hidden-action-btn {
      display: block;
    }
  }
  .v-messages {
    display: none;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
