<template>
  <v-card>
    <v-card-title>Send an email about this note</v-card-title>
    <v-card-text>
      <div v-if="emailFormData.emails.length > 0">
        Recipients: 
        <div 
          v-for="email in emailFormData.emails"
          :key="email"
          class="email-tag"
        >
          {{ email }}
        </div>
      </div>
      <form @submit.prevent="((emailFormData.emails.push(eachEmail)), (eachEmail = ''))">
        <v-text-field
          type="email"
          label="Recipient email"
          placeholder="Type an email and press enter"
          append-icon="mdi-subdirectory-arrow-left"
          v-model="eachEmail"  
        />
        <v-textarea
          name="message"
          label="Message"
          v-model="emailFormData.message"
          value=""
          hint="Type your custom message to the recipient(s)"
        />
        <v-btn
          color="info"
          @click="onEmailSend"
        >
          <v-icon>mdi-send</v-icon>
          Send
        </v-btn>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EmailForm",
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      eachEmail: "",
      emailFormData: {
        emails: []
      }
    }
  },
  methods: {
    async onEmailSend () {
      if (this.emailFormData.emails.length > 0) {
        const response = await this.$store.dispatch("noteEmailSendAction", {noteId: this.note.id, data: this.emailFormData})
        if (response && response.status ==  200) {
          this.$ebus.$emit("newToastMessage", {
            message: response.msg,
          });
          this.$emit("closeEmailModal")
        }
      }
    }
  }
}
</script>

<style scoped>
.email-tag {
  display: inline-block;
  border: 1px solid #151313;
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 3px;
  margin-bottom: 3px;
}
</style>