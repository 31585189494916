<template>
  <div class="note-checkbox-form">
    <form @submit.prevent="addToTheList">
      <v-text-field
        v-model="checkboxInput"
        prepend-icon="mdi-checkbox-blank-outline"
        required
      />
    </form>
    <div>
      <v-checkbox
        v-for="(item, _i) in checkboxes"
        v-model="item.is_done"
        :key="_i"
        :label="item.title"
        disabled
      >
        <template #append>
          <v-btn
            text
            icon
            @click="onCheckedItemDelete(_i)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoteCheckboxForm",
  props: {
    checkBoxItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      checkboxInput: '',
      checkboxes: []
    }
  },
  mounted () {
    if (this.checkBoxItems.length > 0) {
      this.checkboxes = this.checkBoxItems
    }
  },
  methods: {
    addToTheList () {
      this.checkboxes.push({
        title: this.checkboxInput
      })
      this.checkboxInput = ''
    },
    onCheckedItemDelete (index) {
      this.checkboxes.splice(index, 1)
    }
  },
  watch: {
    checkboxes (newValue) {
      this.$emit("onCheckboxItemUpdate", newValue)
    },
    checkBoxItems (newValue) {
      this.checkboxes = newValue
    }
  }
}
</script>

<style lang="scss">
.note-checkbox-form {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .v-input__append-outer {
    pointer-events: all;
  }
  .v-label {
    color: #000000!important;
  }
}
</style>