<template>
  <div>
    <v-row justify="center">
      <v-dialog 
        v-model="openUpdateModal" 
        scrollable 
        max-width="760px"
      >
        <note-form
          @onNoteCreatedOrUpdated="openUpdateModal = false" 
          :update="true"
          :note="note"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import NoteForm from './NoteForm.vue'

export default {
  name: "NoteUpdate",
  components: { NoteForm },
  props: {
    note: {
      type: Object,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noteFormData: {},
      openUpdateModal: this.openModal,
      loading: true
    }
  },
  watch: {
    openUpdateModal (newValue) {
      if (newValue === false) {
        this.$emit("updateModalCollapse", newValue)
      }
    },
    openModal (newValue) {
      if (newValue === true) {
        this.openUpdateModal = true
      }
    }
  }
}
</script>