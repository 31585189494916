<template>
  <div class="label-form">
    <form @submit.prevent="onLevelCreateAndAssign">
      <v-text-field
        outlined
        label="Label name" 
        placeholder="Enter label name" 
        v-model="labelFormData.name"
        @click="selected = false" 
      />
    </form>
    <div 
      class="label-suggestions" 
      v-show="!selected"
    >
      <v-list>
        <v-list-item 
          v-for="item in labels" 
          :key="item.id"
        >
          <v-list-item-title 
            @click="onItemSelect(item)"
          >
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <v-btn
      text 
      color="primary"
      @click="onLevelCreateAndAssign"
    >
      Update
    </v-btn>
    <v-btn
      text 
      color="red"
      @click="removeLabelFromNote"
    >
      Remove
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "CardLabelForm",
  props: {
    note: {
      type: Object,
      required: true
    },
    label: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    labels () {
      const allLabels = this.$store.getters.allLabelList
      if (this.labelFormData.name) {
        return allLabels.filter(item => item.name.toLowerCase().includes(this.labelFormData.name.toLowerCase()))
      }
      return []
    }
  },
  data () {
    return {
      labelFormData: {},
      selected: false
    }
  },
  mounted () {
    if (this.label && this.label.id) {
      this.labelFormData = JSON.parse(JSON.stringify(this.label))
      this.selected = true
    }
  },
  methods: {
    async onLevelCreateAndAssign () {
      let labelId = this.labelFormData ? this.labelFormData.id : null
      this.loading = true
      // Create label
      if (!this.selected) {
        delete this.labelFormData.id  // if id exists otherwise not
        const response = await this.$store.dispatch("createNewLabelAction", this.labelFormData);
        if (response && response.status == 201) {
          labelId = response.data.id
          this.$ebus.$emit("newToastMessage", {
            message: "Label created successfully.",
          });
        } else {
          this.loading = false;
          this.$ebus.$emit("newToastMessage", {
            message: "Failed to create label.",
            color: "danger",
          });
        }
      }
      // Assign the label to the note
      const noteUpdateResponse = await this.$store.dispatch("createUpdateNoteAction", {
        data: {...this.note, label: labelId},
        id: this.note.id,
        method: 'put',
      })
      if (noteUpdateResponse.status === 200) {
        this.$ebus.$emit("newToastMessage", {
          message: 'Label added successfully.',
        });
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: "Failed to add the label to the note.",
          color: "danger",
        });
      }
    },
    onItemSelect (item) {
      this.labelFormData = JSON.parse(JSON.stringify(item))
      this.selected = true
    },
    async removeLabelFromNote () {
      const noteUpdateResponse = await this.$store.dispatch("createUpdateNoteAction", {
        data: {...this.note, label: null},
        id: this.note.id,
        method: 'put',
      })
      if (noteUpdateResponse.status === 200) {
        this.$ebus.$emit("newToastMessage", {
          message: 'Label removed successfully.',
        });
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: "Failed to remove label.",
          color: "danger",
        });
      }
    }
  },
  watch: {
    label: {
      handler (newValue) {
        if (newValue && newValue.id) {
          this.selected = true
          this.labelFormData = JSON.parse(JSON.stringify(newValue))
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">
.label-form {
  background-color: aliceblue;
  padding: 10px;

  .v-list-item__title {
    cursor: pointer;
  }

  .v-text-field__details {
    display: none;
  }
}

</style>