<template>
  <v-row justify="center">
    <note-update
      :note="note"
      :open-modal="openEditModal"
      @updateModalCollapse="openEditModal = false"
    />
    <v-dialog
      v-model="open"
      scrollable
      max-width="760px"
    >
      <each-note-card
        :note="note"
        :window-width="760"
        :show-details="true"
        :modal-opened="open"
        @openEditModal="onOpenEditModal"
        @noteOutOfScoped="open=false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import EachNoteCard from "./card/EachNoteCard.vue";
import NoteUpdate from "./NoteUpdate.vue"

export default {
  name: "NoteModal",
  components: { EachNoteCard, NoteUpdate },
  props: {
    note: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: false || this.openModal,
      openEditModal: false
    };
  },
  methods: {
    onOpenEditModal () {
      this.openEditModal = true
    }
  },
  watch: {
    note: {
      handler(newValue) {
        if (newValue && newValue.id) {
          this.open = true;
        } else {
          this.open = false;
        }
      },
    },
    open(newValue) {
      if (newValue === false) {
        this.$emit("modalClosed");
      }
    },
  },
};
</script>

<style lang="scss">
.v-diaog, .v-dialog--active {
  .note-card {
    .v-card__title {
      cursor: unset;
    }

    .v-card__text {
      height: unset;
    }

    .note-img {
      cursor: unset;
    }
    .v-card__actions {
      display: block;
      margin-left: 0;
    }
    .hidden-action-btn {
      display: block;
      padding: 24px 0 0 0;
    }
  }
}
</style>