<template>
  <div>
    <v-row justify="center">
      <v-dialog 
        v-model="openEmailSendModal" 
        scrollable 
        max-width="760px"
      >
        <email-form
          :note="note"
          @closeEmailModal="$emit('closeEmailModal'), openEmailSendModal = false"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import EmailForm from './EmailForm.vue'

export default {
  name: "EmailSend",
  components: { EmailForm },
  props: {
    note: {
      type: Object,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openEmailSendModal: this.openModal,
      loading: true
    }
  },
  watch: {
    openEmailSendModal (newValue) {
      if (newValue === false) {
        this.$emit("closeEmailModal", newValue)
      }
    },
    openModal (newValue) {
      if (newValue === true) {
        this.openEmailSendModal = true
      }
    }
  }
}
</script>