<template>
  <v-card :loading="loading">
    <v-card-title>
      {{
        update ? "Update note" : "Create new note"
      }}
    </v-card-title>
    <v-divider class="mt-0" />
    <v-card-text>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="createOrUpdateNote"
      >
        <v-text-field
          :rules="validationRules.required"
          label="Title"
          v-model="noteFormData.title"
          placeholder="Note Title"
        />
        <v-select
          :items="Object.values(noteTypeEnum)"
          v-model="noteFormData.type"
          label="Standard"
        />
        <v-textarea
          v-if="noteFormData.type === noteTypeEnum.PLAIN_TEXT"
          name="note_text"
          v-model="noteFormData.note"
          label="Note (Plain Text)"
          value=""
          hint="Enter your note(non styled. Use markdown for styled text)"
        />
        <note-checkbox-form
          v-if="noteFormData.type === noteTypeEnum.CHECKBOX"
          :check-box-items="noteFormData.note_list"
          @onCheckboxItemUpdate="checkboxItemUpdated"
        />
        <v-row v-if="noteFormData.type === noteTypeEnum.MARKDOWN">
          <v-col
            cols="12"
            sm="6"
          >
            <v-textarea
              name="note_markdown"
              label="Note (Markdown)"
              v-model="noteFormData.note"
              value=""
              hint="Enter your styled text"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <b>Preview:</b>
            <div v-html="compiledMarkdown" />
          </v-col>
        </v-row>
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
        >
          {{ update ? "Update" : "Create" }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { NoteTypeEnum } from "@/utils/constants";
import { marked } from "marked/src/marked";
import * as sanitizeHtml from "sanitize-html";
import NoteCheckboxForm from "./NoteCheckboxForm.vue";

export default {
  name: "NoteForm",
  components: { NoteCheckboxForm },
  props: {
    update: {
      type: Boolean,
      default: false,
    },
    note: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    compiledMarkdown: function () {
      return (
        sanitizeHtml(marked(this.noteFormData.note)) ||
        "Write something to get preview"
      );
    },
  },
  data() {
    return {
      noteFormData: {
        type: NoteTypeEnum.PLAIN_TEXT,
        note: "",
      },
      valid: false,
      loading: false,
      noteTypeEnum: NoteTypeEnum,
      validationRules: {
        required: [
          value => !!value  || 'This field is required.',
        ]
      }
    };
  },
  methods: {
    async createOrUpdateNote() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const response = await this.$store.dispatch("createUpdateNoteAction", {
          data: {
            ...this.noteFormData,
            label: (this.note && this.note.label) ? this.note.label.id : null,
          },
          id: this.note ? this.note.id : null,
          method: this.update ? "put" : "post",
        });
        if (response.status === 200 || response.status === 201) {
          this.noteFormData = {
            type: NoteTypeEnum.PLAIN_TEXT,
            note: "",
          }
          this.$emit("onNoteCreatedOrUpdated");
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.loading = false
      }
    },
    checkboxItemUpdated(checkboxes) {
      this.noteFormData.note_list = checkboxes;
    },
  },
  mounted() {
    if (this.note) {
      this.noteFormData = JSON.parse(JSON.stringify(this.note));
    }
  },
  watch: {
    "note.id": {
      handler() {
        this.noteFormData = JSON.parse(JSON.stringify(this.note));
      },
      deep: true,
    },
  },
};
</script>