<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h4">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="cancelBtnColor"
            text
            @click="onCancel"
          >
            {{ cancelBtnTxt }}
          </v-btn>
          <v-btn
            :color="confirmBtnColor"
            text
            @click="onConfirm"
          >
            {{ confirmBtnTxt }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "MyAlert",
  data () {
    return {
      dialog: false,
      description: null,
      resolve: null,
      reject: null,
      title: "Alert!!",
      confirmBtnTxt: "Confirm",
      confirmBtnColor: "red",
      cancelBtnTxt: "Cancel",
      cancelBtnColor: "primary"
    }
  },
  methods: {
    open({title, description = "", confirmBtnTxt = "Confirm", cancelBtnTxt = "Cancel"}) {
      this.title = title
      this.description = description
      this.confirmBtnTxt = confirmBtnTxt
      this.cancelBtnTxt = cancelBtnTxt
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onConfirm () {
      this.resolve(true)
      this.dialog = false
    },
    onCancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>