<template>
  <v-card-actions>
    <my-alert ref="confirm" />
    <email-send
      :note="note"
      :open-modal="openEmailSendModal"
      @closeEmailModal="openEmailSendModal = false"
    />
    <div
      class="hidden-action-btn"
    >
      <v-btn
        icon
        color="primary"
        v-if="note.status === 'active'"
        title="Send email about the note"
        @click="openEmailSendModal = true"
      >
        <!-- Email Send Button -->
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-menu 
        offset-y 
        v-if="note.status === 'active'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="dark"
            v-bind="attrs"
            v-on="on"
            title="Image update/remove"
          >
            <!-- Image Upload Button -->
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
              text 
              color="primary"
              @click="handleImageUploadBtnClick(note)"
            >
              {{ `${note.cover_photo ? 'Update' : 'Upload'}` }}
            </v-btn>
          </v-list-item>
          <v-list-item v-if="note.cover_photo">
            <v-btn
              text
              color="red"
              @click="removeCoverImage(note)"
            >
              Remove
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <input
        ref="coverImageUploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      >
      <v-menu 
        offset-y
        v-if="note.status === 'active'"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon
            v-bind="attrs"
            v-on="on"
            color="blue"
            title="Add/Update/Remove Label"
          >
            <!-- Level Button -->
            <v-icon>mdi-label</v-icon>
          </v-btn>
        </template>
        <card-label-form 
          :note="note" 
          :label="note.label"
        />
      </v-menu>
      <v-btn
        icon
        v-if="note.status === 'active'"
        color="blue-grey"
        @click="onNoteArchive"
        title="Archive the note"
      >
        <!-- Archive Button -->
        <v-icon>mdi-package-down</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="note.status === 'active'"
        color="primary"
        @click="$emit('openEditModal')"
        title="Update the note"
      >
        <!-- Edit Button -->
        <v-icon>mdi-pencil-box</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="note.status === 'active'"
        color="pink"
        @click="onNoteDelete"
        title="Delete the note"
      >
        <!-- Delete Button -->
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <v-spacer />
    <v-btn
      icon
      v-if="note.status !== 'active'"
      color="info"
      @click="onNoteRestore"
      title="Restore the note"
    >
      <!-- Restore Button -->
      <v-icon>mdi-arrow-left-top</v-icon>
    </v-btn>
    <v-btn
      icon
      v-if="note.status !== 'active'"
      color="red"
      @click="onNotePermanentDelete"
      title="Delete Permanently"
    >
      <!-- Restore Button -->
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-btn
      icon
      @click="toggleNoteDetails"
      v-if="!modalMode"
    >
      <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script>
import MyAlert from '@/components/common/MyAlert.vue';
import EmailSend from '../EmailSend.vue';
import CardLabelForm from './CardLabelForm.vue';
export default {
  components: { MyAlert, CardLabelForm, EmailSend },
  name: "CardAction",
  props: {
    note: {
      type: Object,
      required: true,
    },
    modalMode: {
      type: Boolean,
      required: false,
      default: false
    }
    
  },
  data: () => ({
    show: false,
    selectedNote: null,
    loading: false,
    openEmailSendModal: false
  }),
  methods: {
    handleImageUploadBtnClick(note) {
      this.$refs.coverImageUploader.click();
      this.selectedNote = note;
    },
    toggleNoteDetails() {
      this.show = !this.show;
      this.$emit("toggleNoteDetails");
    },
    async onFileChanged(e) {
      this.loading = true;
      const file = e.target.files[0];
      const imageUploadResp = await this.$store.dispatch("uploadImage", file);
      if (imageUploadResp && imageUploadResp.status === 200) {
        this.$ebus.$emit("newToastMessage", {
          message: "Image upload successful.",
        });
        await this.$store.dispatch("updateNoteCoverImage", {
          ...imageUploadResp.data,
          noteId: this.selectedNote.id,
          status: this.selectedNote.status
        });
        this.$ebus.$emit("newToastMessage", {
          message: "Image updated to the note.",
        });
        this.loading = false;
      } else {
        this.loading = false;
        this.$ebus.$emit("newToastMessage", {
          message: imageUploadResp.msg,
          color: "danger",
        });
      }
    },
    async removeCoverImage (note) {
      this.loading = true
      const response = await this.$store.dispatch("updateNoteCoverImage", {
        relative_path: null,
        noteId: note.id,
        status: note.status
      });
      if (response && response.status == 200) {
        this.$ebus.$emit("newToastMessage", {
          message: "Image removed from the note.",
        });
        this.loading = false;
      } else {
        this.loading = false;
        this.$ebus.$emit("newToastMessage", {
          message: "Failed to remove cover image.",
          color: "danger",
        });
      }
    },
    async onNoteDelete () {
      this.loading = true;
      if (await this.$refs.confirm.open({title: "Delete!!", description: "Are you sure about delete?"})) {
        const response = await this.$store.dispatch("updateNoteStatusAction", {
          noteId: this.note.id,
          status: "deleted",
          note: this.note
        })
        if (response && response.status === 200) {
          this.$emit("noteOutOfScoped")
          this.$ebus.$emit("newToastMessage", {
            message: "Note moved to trash."
          });
          this.loading = false
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async onNoteRestore () {
      this.loading = true;
      if (await this.$refs.confirm.open({title: "Restore!!", description: "Are you sure about restore?"})) {
        const response = await this.$store.dispatch("updateNoteStatusAction", {
          noteId: this.note.id,
          status: "active",
          note: this.note
        })
        if (response && response.status === 200) {
          // this.$emit("noteOutOfScoped")
          this.$ebus.$emit("newToastMessage", {
            message: "Note restored."
          });
          this.loading = false
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async onNotePermanentDelete () {
      this.loading = true;
      if (await this.$refs.confirm.open({title: "Permanent Delete!!", description: "Are you sure about delete?"})) {
        const response = await this.$store.dispatch("deleteNoteAction", {status: this.note.status, noteId: this.note.id})
        this.loading = false
        this.$ebus.$emit("newToastMessage", {
          message: response.msg
        });
        
      } else {
        this.loading = false
      }
    },
    async onNoteArchive () {
      this.loading = true;
      if (await this.$refs.confirm.open({title: "Archive!!", description: "Are you sure about archive?"})) {
        const response = await this.$store.dispatch("updateNoteStatusAction", {
          noteId: this.note.id,
          status: "archived",
          note: this.note
        })
        if (response && response.status === 200) {
          this.$emit("noteOutOfScoped")
          this.$ebus.$emit("newToastMessage", {
            message: "Note archived successfully."
          });
          this.loading = false;
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    }
  },
  watch: {
    loading (newValue) {
      this.$emit("cardLoadingToggled", newValue)
    }
  }
};
</script>
