<template>
  <v-expand-transition>
    <div v-show="show">
      <v-divider />
      <!-- Only plain text loader -->
      <v-card-text v-if="note.type === noteTypeEnum.PLAIN_TEXT">
        {{ note.note }}
      </v-card-text>
      <!-- Checkbox Loader -->
      <v-card-text v-if="note.type === noteTypeEnum.CHECKBOX">
        <v-checkbox
          :disabled="note.status !== 'active'"
          v-for="n_item in noteItems"
          :key="n_item.order"
          v-model="n_item.is_done"
          @change="onCheckboxChange(note.id, n_item)"
        >
          <template v-slot:label>
            <div>
              <span v-if="!n_item.is_done">{{ n_item.title }}</span>
              <span v-else>
                <s>{{ n_item.title }}</s>
              </span>
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
      <!-- Markdown Loader -->
      <v-card-text
        v-if="note.type === noteTypeEnum.MARKDOWN"
        v-html="htmlNote"
      />
    </div>
  </v-expand-transition>
</template>

<script>
import { NoteTypeEnum } from "@/utils/constants";
import { marked } from "marked"
import sanitizeHtml from "sanitize-html"

export default {
  name: "CardDetailsExpand",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modalMode: {
      type: Boolean,
      default: false,
    },
    note: {
      type: Object,
      required: true,
    },
  },
  computed: {
    noteItems: {
      get() {
        return JSON.parse(JSON.stringify(this.note.note_list));
      },
      set(value) {
        return value;
      },
    },
  },
  data() {
    return {
      noteTypeEnum: NoteTypeEnum,
      htmlNote: ''
    };
  },
  mounted () {
    this.htmlNote = this.parseMarkdown(this.note.note)
  },
  methods: {
    async onCheckboxChange(noteId, checkItem) {
      this.loading = true;
      const actionPayload = { noteId: noteId, checkItem: checkItem };
      const responseData = await this.$store.dispatch(
        "updateCheckboxItem",
        actionPayload
      );
      this.loading = false;
      this.$ebus.$emit("newToastMessage", {
        message: responseData.msg ? responseData.msg : "Note updated.",
      });
    },
    parseMarkdown (value) {
      return sanitizeHtml(marked(value))
    }
  },
  watch: {
    'note.note' (newValue) {
      if (newValue) {
        this.htmlNote = this.parseMarkdown(newValue)
      }
    }
  }
};
</script>
